.member_checkbox {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}
.member_checkbox input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}
.member_checkbox label{
    font-size: 16px;
    /* margin-left: 20px; */
}
.member_checkbox input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}

.member_checkbox input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  background-color: #6200ee;
  border: solid #6200ee;
}
.member_checkbox input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 6px;
}

.text_field .MuiOutlinedInput-input{
    padding: 10px;
}
  